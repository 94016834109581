import '../../css/embed/main.scss';

/**
 * Embed class that can be used as the Controller on "the other side" (inside the embedding page) to enable some of the
 * built-in features of the Maglr system.
 */
class Embed {

    constructor(){
        window.addEventListener("message", (event) => this.messageReceived(event), false);
        // this.container = document.getElementById("maglrSpreadEmbed");
        this.embeds = {};

        this.init();
    }

    init(){
        let embeds = document.querySelectorAll("[data-maglrembed]");

        for(let i = 0; i < embeds.length; i++){
            this.addEmbed(embeds[i].dataset.maglrembed, embeds[i]);
        }

        this.addLoader();

        setTimeout(() => {
            this.checkHandshakes()
        }, 2000)

    }

    addEmbed(embedId, element){
        if(this.embeds[embedId]){
            return;
        }
        this.embeds[embedId] = {
            embedId: embedId,
            container: element,
            viewport: {
                width: undefined,
                height: undefined
            },
            version: null,
        };
        if( element.dataset.nomobile ){
            this.embeds[embedId].noMobile = true;
            this.bindResizeListener();
        }
        let iframe = element.querySelector('iframe');
        if(iframe.getAttribute('src').indexOf("banner") > -1){
            this.embeds[embedId].loader = false;
            this.embeds[embedId].banner = true;
            this.injectOverlay(embedId, iframe);
        }

        return this.embeds[embedId];
    }

    /**
     * The message Listener function that handles all messages coming from the embedded spread
     * @param {Object} event
     */
    messageReceived(event){
        let message = event.data,
            embedId = message.embedId,
            embed = this.embeds[embedId]
        ;

        if( !embed ){
            let element = document.querySelector(`[data-maglrembed='${embedId}']`);
            if(element){
                embed = this.addEmbed(embedId, element);
            }else{
                if(message.message === "EMBED_ERROR"){
                    for(let hash in this.embeds){
                        this.removeLoader( this.embeds[hash] );
                    }
                }
                return;
            }
        }

        switch (message.message) {
            case "EMBED_ERROR":
                this.removeLoader(embed);
                break;
            case "HELLO":
                if(!embed.handshaked){
                    embed.handshaked = true;
                    this.removeLoader(embed);

                    embed.iframeWindow = event.source;

                    embed.scrolling_scenes = message.data.scrolling_scenes;
                    if (message.data.version) {
                        embed.version = message.data.version
                    }

                    this.sendMessage(embed, "HANDSHAKE", {
                        type : embed.container.dataset.type,
                        maxsize : embed.container.dataset.maxsize,
                        viewport: this.getViewport(embed),
                        location: window.location.href,
                    });

                    this.setIframePadding(embed, message.data);

                    if(embed.container.dataset.type === "fullHeight"){ // Only when embedded as fullHeight
                        this.bindScrollListener();
                    }

                    if(this._plugins){
                        try{
                            for(let name in this._plugins){
                                this._plugins[name].afterHandshake(embedId);
                            }
                        }catch (e) {
                            console.warn(`plugin Error: afterHandshake`, e);
                        }
                    }
                }
                break;
            case "SIZE_CHANGED": {
                //spread informs that its size is changed.
                let setPadding = true;
                if (embed.container.dataset.type === "fullHeight" && embed.container.dataset.maxsize === "width-height" && message.data.width) {
                    const width = this.getElementContentWidth(embed.container) || this.getElementContentWidth(embed.container.parentElement) || window.innerWidth;
                    embed.switchPoint = embed.switchPoint || message.data.switchPoint || false;

                    if (message.data.width <= width) {
                        Object.assign(embed.container.style, {
                            padding: '0px',
                            width: `${message.data.width}px`,
                            height: `${message.data.height}px`
                        })
                        setPadding = false;
                        embed.mode = 'width-height';
                        this.bindResizeListener();
                    }
                }

                if (setPadding) {
                    if (embed.scrolling_scenes) {
                        this.setIframePadding(embed, message.data);
                    } else {
                        if (embed.container.dataset.type === "fullHeight") {
                            this.setIframePadding(embed, message.data);
                        }
                    }
                }
                const viewport = this.getViewport(embed)
                if (viewport.width !== embed.viewport.width) {
                    this.sendMessage(embed, "SET_VIEWPORT", {viewport});
                    embed.viewport = viewport
                }
                break;
            }
            case "NEW_SCENE":
                //spread informs that its size is changed.
                if( ! embed.scrolling_scenes){
                    this.setIframePadding(embed, message.data);
                    if(embed.container.dataset.type === "fullHeight"){

                        let viewport = this.getViewport(embed);
                        viewport.height = message.data.height;
                        this.sendMessage(embed, "SET_VIEWPORT", {viewport: viewport } );
                    }
                }
                break;
            case "SCROLL_TO":
                if(message.data.browser === "ie" || message.data.browser === "edge" ){
                    window.scrollTo(0, embed.container.offsetTop + message.data.scroll);
                }else{
                    window.scrollTo({
                        top: embed.container.offsetTop + message.data.scroll,
                        behavior: "smooth"
                    });
                }
                break;
            case "BANNER_CLICKED":
                this.openEmbedFullscreen(embedId, message.backgroundColor);
                break;
            case "BANNER_SIZE":
                try{
                    if (message.height > 100) {
                        embed.container.style.height = `${(message.height+10)}px`; // +10 to have some safeMargin
                        embed.container.style.padding = `0`;
                    }
                }catch(error){
                    console.error(error);
                }
                break;
            case "CLOSE_EMBED":
                this.closeEmbedFullscreen(embedId);
                break;
            case "PUSH_GTM":
                if (message.data && message.data.event === 'maglrUserEvent') {
                    this.pushGTMEvent(message.data);
                }
                break;
        }
    }

    /**
     * Send a message to the embedded spread
     * @param {String} message
     * @param {{}} data
     */
    sendMessage(target, message, data = {}){
        if(typeof message === "string"){
            message = {message: message};
        }

        message.message += ".EMBED";
        message.data = data;

        target.iframeWindow && target.iframeWindow.postMessage(message, "*"); //TODO this can be embed.maglr.com
    }

    /**
     * Calculate the new padding for the spread based on the given height and set it.
     * @param {Object} target
     * @param {Object} data
     */
    setIframePadding(target, data){
        if(target.container.dataset.type === "fullHeight" && data.height > 0){
            let containerWidth = target.container.clientWidth,
                width = this.getElementContentWidth(target.container) + this.getElementMargins(target.container),
                padding,
                height = data.height
            ;
            if(target.container.dataset.maxsize === 'width-height' && data.width < width){
                return;
            }
            if (target.version >= 12) {
                target.container.style.height = `${height}px`;
                target.container.style.paddingBottom = '0px';
                target.container.children[0].style.position = 'sticky';
                target.container.children[0].style.top = 0;
                target.container.children[0].style.maxHeight = '100vh';
                return
            }

            padding = (height * ( target.scale || 1) / width) * 100;


            if(padding < 1 || padding > 10000){
                padding = 56.25;
            }
            target.container.style.paddingBottom = `${padding}%`;
            target.container.style.width = `auto`;
            target.container.style.removeProperty('height');

            target.mode = 'padding';

            target.container.children[0].style.left = 0;

            //check if new padding caused a scrollbar to take up some extra width so decreasing the available width
            if(containerWidth !== target.container.clientWidth){
                this.setIframePadding(target, data);
            }

            this.resizeHandler();
            this.scrollHandler();

        }
    }

    bindResizeListener(){
        if( ! this.boundResize){
            this.boundResize = true;

            window.addEventListener('resize', () => {
                this.resizeHandler()
            }, true);

            this.resizeHandler()
        }
    }

    resizeHandler(){
        if( this.boundResize ){
            for(let id in this.embeds){
                let container = this.embeds[id].container,
                    width = container.clientWidth,
                    iframe,
                    iframe_width,
                    scale,
                    transform,
                    transform_origin,
                    threshold = 501
                ;
                if(this.embeds[id].noMobile){

                    if(width < threshold){
                        //width less then threshold so apply scale and width
                        iframe_width = (threshold / width) * 100;
                        scale = width / threshold;

                        transform = `scale(${scale})`;
                        transform_origin = `top left`;
                    }else{

                        if(this.embeds[id].scale < 1){
                            scale = 1;
                            iframe_width = 100;
                            transform = ``;
                            transform_origin = ``;
                        }else{
                            //no change needed
                            continue;
                        }

                    }

                    this.embeds[id].scale = scale;
                    iframe = container.querySelector("iframe");
                    iframe.style.width = `${iframe_width}%`;
                    iframe.style.height = `${iframe_width}%`;
                    iframe.style.maxWidth = 'none';
                    container.style.transform = transform;
                    container.style.transformOrigin = transform_origin;
                }

                if(this.embeds[id].mode === 'width-height' && this.embeds[id].container.parentNode.clientWidth < this.embeds[id].switchPoint){
                    Object.assign(this.embeds[id].container.style, {paddingBottom: `56.25%`, width:`auto`, height: `unset`})
                }
            }
        }
    }

    /**
     * Bind a scrollListener to the window when its not done already.
     */
    bindScrollListener(){
        if(!this.boundScroll){
            this.boundScroll = true;

            window.addEventListener('scroll', () => {
                this.scrollHandler();
            });
        }
    }

    scrollHandler() {

        for(let id in this.embeds){
            let currentScroll = window.scrollY - this.embeds[id].container.offsetTop;

            this.sendMessage(this.embeds[id], "UPDATE_SCROLL_POSITION", {scroll: {
                    top: currentScroll,
                    bottom: currentScroll + window.innerHeight
                }
            })
        }
    }

    /**
     * Get the viewport width and height.
     * @returns {{width: number, height: number}}
     */
    getViewport(target){
        let viewport;
        if(target.banner){
            let iframe = document.getElementById(`maglrEmbed_${target.embedId}`);
            viewport = {
                width : iframe.clientWidth,
                height : iframe.clientHeight
            }
        }else if( ! target.scrolling_scenes || target.container.dataset.type === "static" ){
            viewport = {
                width : target.container.clientWidth,
                height : target.container.clientHeight
            }
        }else{
            viewport = {
                width : this.getElementContentWidth(target.container) || this.getElementContentWidth(target.container.parentElement) || window.innerWidth,
                height : window.innerHeight
            }
        }

        if(target.noMobile && target.scale < 1){
            viewport.width  = Math.round(viewport.width / target.scale);
            viewport.height = Math.round(viewport.height / target.scale);
        }
        return viewport;
    }

    getElementContentWidth(element) {
        try{
            let styles = window.getComputedStyle(element);
            return element.clientWidth - (parseFloat(styles.paddingLeft) + parseFloat(styles.paddingRight) );
        } catch {
            return false;
        }
    }

    getElementMargins(element) {
        try{
            let styles = window.getComputedStyle(element);
            return parseFloat(styles.marginLeft) + parseFloat(styles.marginRight);
        } catch {
            return 0;
        }
    }

    /**
     *
     */
    addLoader(){
        if( ! this.loaderAdded ){
            this.loaderAdded = true;
            let style = document.createElement('style');
            style.type = 'text/css';
            style.appendChild(document.createTextNode(this.getLoaderCSS()));
            document.querySelector("head").appendChild(style);
        }

        for( let hash in this.embeds ){
            if (!Object.hasOwn(this.embeds[hash], 'loader')) {
                this.embeds[hash].loader = document.createElement('div');
                this.embeds[hash].loader.className = 'maglrEmbedLoader';
                this.embeds[hash].container.appendChild(this.embeds[hash].loader);
            }
        }
    }

    removeLoader(embed){
        try{
            embed.loader.style.display = "none";
        } catch {
            // continue regardless of error
        }
    }

    /**
     *
     * @returns {string}
     */
    getLoaderCSS(){
        return `.maglrEmbedLoader:before {
                  content: '';
                  box-sizing: border-box;
                  position: absolute;
                  top: 100px;
                  left: 50%;
                  width: 50px;
                  height: 50px;
                  margin-top: -25px;
                  margin-left: -25px;
                  border-radius: 50%;
                  border: 4px solid #eee;
                  border-top-color: #bbb;
                  border-right-color: #bbb;
                  animation: maglrLoadAnimation 1s linear infinite;
                }

                @keyframes maglrLoadAnimation {
                     to {transform: rotate(360deg);}
                }`
            ;
    }

    registerPlugin(plugin){
        if(!this._plugins){
         this._plugins = {};
        }

        if(this._plugins[plugin.name]){
            console.warn(`plugin with name ${plugin.name} already registered!`);
            return;
        }

        this._plugins[plugin.name] = plugin;
    }

    getEmbeds(){
        return this.embeds;
    }

    injectOverlay(embedId, iframe){
        this.injectBannerOverlayCss();

        let embedUrl = iframe.getAttribute('src').replace('banner=1', 'banner=0');
        let htmllayover =
            `<div class="maglr-overlay" id='maglrOverlay_${embedId}'>
                <div class="maglr-blackoverlay"></div>
                <iframe id='maglrEmbed_${embedId}'class="maglr-iframe" data-src="${embedUrl}" seamless="seamless" frameborder="0" allowtransparency="true" scrolling="auto" width="100%" height="100%" allowfullscreen=""></iframe>
            </div>`
        ;

        let mouseEnter = (event) => {
            if (event.target.parentNode && event.target.parentNode.dataset && event.target.parentNode.dataset.maglrembed) {
                event.target.removeEventListener('mouseenter', mouseEnter);
                let iframe = document.getElementById(`maglrEmbed_${event.target.parentNode.dataset.maglrembed}`);
                if (iframe && !iframe.getAttribute('src') && iframe.dataset && iframe.dataset.src) {
                    iframe.setAttribute('src', iframe.dataset.src);
                }
            }
        }

        iframe.addEventListener('mouseenter', mouseEnter);

        document.querySelector("body").insertAdjacentHTML('beforeend', htmllayover);
    }

    injectBannerOverlayCss(){
        if(!this.styleFullscreenAdded){
            this.styleFullscreenAdded = true;
            let style = document.createElement('style');
            style.type = 'text/css';
            style.appendChild(document.createTextNode(`
            .maglr-overlay {
                transform: translateX(100%);
                opacity: 0;
                position: fixed;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100%;
                z-index: 100000;
                overflow: hidden;
                transition: opacity 600ms;
            }
            .maglr-blackoverlay {
                position:absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
                z-index:1;
                background:rgba(0,0,0,0.28);
            }
            .maglr-open-overlay {
                transform: translateX(0%) ;
                opacity: 1 ;
            }
            .maglr-close-overlay {
                transition-delay: 500ms;
                opacity: 0;
            }
            .maglr-iframe {
                background:white;transform: translateY(100%); transition: all 600ms; transition-delay: 300ms; z-index:2; position: absolute;
            }
            .maglr-open-overlay .maglr-iframe{
                transform: translateY(0%);
            }
            .maglr-open-overlay.maglr-close-overlay .maglr-iframe{
                transform: translateY(100%);
            }
            .maglr-disable-scroll {
                height:100vh;
                overflow:hidden;
            }
            `));
            document.querySelector("head").appendChild(style);
        }
    }

    openEmbedFullscreen(embedId){
        document.body.classList.add(`maglr-disable-scroll`);
        let iframe = document.getElementById(`maglrEmbed_${embedId}`);
        if (!iframe.getAttribute('src')) {
            iframe.setAttribute('src', iframe.dataset.src);
        }
        iframe.parentNode.classList.add(`maglr-open-overlay`);

        setTimeout(() => {
            this.scrollToSmoothly((window.pageYOffset) + 200, 400);
        }, 300);

    }

    closeEmbedFullscreen(embedId){
        let iframe = document.getElementById(`maglrEmbed_${embedId}`);
        iframe.parentNode.classList.add(`maglr-close-overlay`);
        document.body.classList.remove(`maglr-disable-scroll`);

        setTimeout(() => {
            this.scrollToSmoothly((window.pageYOffset) - 200, 400);
          }, 300);
        setTimeout(()=>{
            iframe.parentNode.classList.remove('maglr-open-overlay');
            iframe.parentNode.classList.remove('maglr-close-overlay');
        },1000);
    }

    scrollToSmoothly(pos, time = 500){
        let currentPos = window.scrollY || window.screenTop;
        let start = null;

        window.requestAnimationFrame(function step(currentTime){
            start = !start? currentTime: start;
            if( currentPos < pos){
                let progress = currentTime - start;
                window.scrollTo(0, ((pos-currentPos)*progress/time)+currentPos);
                if(progress < time){
                    window.requestAnimationFrame(step);
                } else {
                    window.scrollTo(0, pos);
                }
            } else {
                let progress = currentTime - start;
                window.scrollTo(0, currentPos-((currentPos-pos)*progress/time));
                if(progress < time){
                    window.requestAnimationFrame(step);
                } else {
                    window.scrollTo(0, pos);
                }
            }
        });
    }

    pushGTMEvent(data) {
        try {
            window.dataLayer && window.dataLayer.push(data);
        } catch (e) {
            console.warn('GTM Error: ', e);
        }
    }

    checkHandshakes() {
        for( let hash in this.embeds ){
            if (!this.embeds[hash].handshaked) {
                this.embeds[hash].retried = true;
                try {
                    this.embeds[hash].iframeWindow = this.embeds[hash].container.children[0].contentWindow
                    this.sendMessage(this.embeds[hash], "HANDSHAKE_MISSED");
                } catch (e) {
                    console.log(e)
                }
            }
        }
    }
}

if(!window.maglrEmbed){
    window.maglrEmbed = new Embed();
}else{
    window.maglrEmbed.init();
}
